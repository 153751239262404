
* {
    box-sizing: border-box;
  }
  
  :root {
    --white: #fff;
    --grey: #f1f4f8b0;
    --dark-grey: #6b7c93;
    --green: #24b47e;
    --teal: #4F96CE;
    --blue: #6772e5;
    --dark-blue: #4F3EF5;
    --spacer: 28px;
  }
  
  body {
    font-family: -apple-system, system-ui, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: var(--dark-grey);
  }
  
  a {
    text-decoration: none;
    color: var(--blue);
  }
  
  a:hover,
  a:focus {
    color: var(--dark-blue);
  }
  
  ul {
    list-style: none;
    padding-left: 0;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .page-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  .page-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  .page-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .page-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }
  /* styles.css */
:root {
  --background: hsl(0, 0%, 100%);
  --foreground: hsl(240, 10%, 3.9%);
  --card: hsl(0, 0%, 100%);
  --card-foreground: hsl(240, 10%, 3.9%);
  --popover: hsl(0, 0%, 100%);
  --popover-foreground: hsl(240, 10%, 3.9%);
  --primary: hsl(240, 5.9%, 10%);
  --primary-foreground: hsl(0, 0%, 98%);
  --secondary: hsl(240, 4.8%, 95.9%);
  --secondary-foreground: hsl(240, 5.9%, 10%);
  --muted: hsl(240, 4.8%, 95.9%);
  --muted-foreground: hsl(240, 3.8%, 45%);
  --accent: hsl(240, 4.8%, 95.9%);
  --accent-foreground: hsl(240, 5.9%, 10%);
  --destructive: hsl(0, 72%, 51%);
  --destructive-foreground: hsl(0, 0%, 98%);
  --border: hsl(240, 5.9%, 90%);
  --input: hsl(240, 5.9%, 90%);
  --ring: hsl(240, 5.9%, 10%);
  --chart-1: hsl(173, 58%, 39%);
  --chart-2: hsl(12, 76%, 61%);
  --chart-3: hsl(197, 37%, 24%);
  --chart-4: hsl(43, 74%, 66%);
  --chart-5: hsl(27, 87%, 67%);
  --radius: 0.5rem;
  --font-heading: 'Inter', sans-serif;
  --font-body: 'Inter', sans-serif;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  font-family: var(--font-body);
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
}

.card {
  background-color: var(--card);
  color: var(--card-foreground);
  border-radius: var(--radius);
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.card-header {
  margin-bottom: 1rem;
}

.card-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.card-header p {
  color: var(--muted-foreground);
}

.card-content {
  margin-top: 0.5rem;
}

.grid-container {
  display: grid;
  gap: 1rem;
}

.grid-container-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-container-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-container-1 {
  grid-template-columns: 1fr;
}
