/* src/components/Login.css */
.login-container {
    max-width: 340px;
    margin: 80px auto 100px; /* Aumentar margen superior e inferior */
    padding: 30px; /* Más padding para una apariencia más espaciosa */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra más suave y extensa */
    background-color: #fff;
    border-radius: 10px; /* Bordes más redondeados para un look moderno */
    position: relative; /* Posición relativa para centrado vertical opcional */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrado de los elementos internos */
}

.form-group {
    width: 100%; /* Asegurando que los inputs usen todo el ancho disponible */
    margin-bottom: 25px; /* Más espacio entre los campos */
}

.form-group label {
    display: block;
    margin-bottom: 8px; /* Más espacio entre la etiqueta y el input */
    font-size: 16px; /* Fuente más grande para las etiquetas */
    font-weight: 500; /* Semi-negrita para mejor legibilidad */
}

.form-group input {
    width: 100%;
    padding: 12px; /* Padding más amplio para un toque más fácil */
    box-sizing: border-box;
    border: 2px solid #ccc; /* Borde más definido */
    border-radius: 6px; /* Bordes suavemente redondeados */
    font-size: 14px; /* Tamaño adecuado de la fuente para los inputs */
}


.login-button {
    width: 100%;
    padding: 12px;
    margin: 0 10px;
    background-color: white;
    color: #4B0082; /* Color morado fuerte */
    border: 2px solid #4B0082; /* Contorno morado */
    border-radius: 20px; /* Forma de cápsula */
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Transición suave para hover */
}

.login-button:hover {
    background-color: #D8BFD8; /* Color morado clarito */
    color: white;
    border-color: #D8BFD8; /* Cambiar color del contorno al hacer hover */
}

.login-links {
    margin-top: 15px;
    text-align: center;
}

.login-links a {
    color: #007BFF;
    text-decoration: none;
    margin: 0 10px;
    font-size: 14px;
}

.login-links a:hover {
    text-decoration: underline;
}
