.company-dropdown {
    width: 18.5rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .heading-link {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .heading-link a {
    text-decoration: none;
    color: #333;
  }
  
  .heading-link a:hover {
    text-decoration: underline;
  }
  
  .link-list {
    list-style: none;
    padding: 0;
    margin-left: 25px;
  }
  
  .link-list li {
    margin-bottom: 0.5rem;
  }
  
  .help-center-item {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .help-center-item svg {
    margin-right: 8px;
    fill: #6f42c1;
  }
  
  .help-center-item a {
    color: #6f42c1;
    text-decoration: none;
    font-weight: bold;
  }
  