/* Add these styles to your stylesheet */

.consultation-page {
    background-color: #ffffff;
    min-height: 100vh;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .consultation-page h1 {
    color: #6200ee; /* Purple color for text */
  }
  
  .consultation-card {
    border: none;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
  
  .consultation-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
  
  .consultation-section h3 {
    margin-bottom: 15px;
    color: #6200ee;
  }
  
  .download-button {
    background-color: #6200ee;
    color: #fff;
    border: none;
    margin-top: 10px;
  }
  
  .download-button:hover {
    background-color: #4d00c2;
  }
  
  .btn-purple {
    background-color: #6200ee;
    color: #fff;
  }
  
  .btn-purple:hover {
    background-color: #4d00c2;
  }
  
  .doctor-status {
    color: #6200ee;
  }
  
  .doctor-card-header {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
  }
  
  .doctor-card {
    background-color: #ffffff;
    color: #6200ee;
  }
  
  .doctor-card-title {
    color: #6200ee;
  }
  
  .video-call-button {
    background-color: #6200ee;
    color: #fff;
    border: none;
    margin-top: 10px;
    font-size: 20px; /* Tamaño de fuente más grande */
    font-weight: bold; /* Fuente en negrita para hacer el texto más atractivo */
    padding: 10px 20px; /* Espaciado alrededor del texto e icono */
    display: inline-flex; /* Para alinear icono y texto horizontalmente */
    align-items: center;
  }
  .video-icon {
    margin-right: 10px; /* Espaciado entre el icono y el texto */
    font-size: 24px; /* Tamaño del icono */
  }
  .video-call-text {
    display: inline-block; /* Asegura que el texto se alinee correctamente */
  }
  
  .video-call-button:hover {
    background-color: #4d00c2;
  }
  .doctor-card-title {
    color: #5a5a5a; /* Cambia a un color más profesional */
    font-size: 2.5em;
  }
  
  .doctor-status {
    display: inline-flex;
    align-items: center;
    font-size: 0.9em;
  }
  
  .doctor-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .doctor-photo-large {
    border: 2px solid #ddd;
    margin-top: 15px;
  }
  
  .card-text strong {
    color: #6c757d;
  }
  
  .card-body {
    background-color: #ffffff;
  }
  
  .doctor-overview {
    margin: 20px auto;
    padding: 20px;
    max-width: 500px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .doctor-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 15px;
  }
  

  
  .status-info {
    text-align: center;
    margin-top: 15px;
  }
  
  .doctor-status {
    font-weight: bold;
  }
  
  @media (max-width: 576px) {
    .doctor-overview {
      padding: 15px;
    }
  
    .doctor-card-title {
      font-size: 20px;
    }
  
    .doctor-photo {
      width: 120px;
      height: 120px;
    }
  
    .status-info {
      margin-top: 10px;
    }
    .consultation-card {
      padding: 15px;
    }
  
   
  }
  