.react-datepicker {
    background-color: #e0f7fa;
    border: none;
}

.react-datepicker__header {
    background-color: #00796b;
    border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #ffffff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #00796b;
    color: #ffffff;
    border-radius: 0.3rem;
}
