.hero {
  position: relative;
  height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.hero-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transform: translateY(-30px); /* Mover 30px hacia arriba */
}

.hero-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  font-size: 3rem;
  margin: 0;
}

.hero p {
  font-size: 1.5rem;
  margin: 10px 0 0;
}

.hero-text {
  color: white;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .hero {
    height: auto; /* Ajustar la altura automáticamente */
  }

  .hero-content {
    grid-template-columns: 1fr; /* Cambiar a una columna */
    gap: 10px;
    padding: 20px;
    transform: translateY(0); /* Restablecer la posición */
  }

  .hero-right, .hero-left {
    width: 100%;
  }

  .hero-left {
    order: 2; /* Mostrar después del login */
  }

  .hero-right {
    order: 1; /* Mostrar primero */
  }
}


