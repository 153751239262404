/* Dark mode styles */
body.dark-mode {
    background-color: #333;
    color: white;
}
/* div.dropdown-menu{
    background-color: #41463D;
} */

/* Ajustes específicos */
.profile-photo-edit.dark-mode {
    border: 2px solid white;
}

/* Estilos de navbar en modo oscuro */
.navbar.dark-mode {
    background-color: #41463D;
    color: white;
}

.navbar.dark-mode .brand-link {
    color: #9D8DF1;
}

.navbar.dark-mode .navbar-links a {
    color: #9D8DF1;
}

.navbar.dark-mode .navbar-links a:hover {
    color: #5a189a;
}

.btn-purple.dark-mode {
    background-color: #9D8DF1;
    color: white;
}

.btn-purple.dark-mode:hover {
    background-color: #5a189a;
    color: white;
}

.font-title.dark-mode {
    color: #9D8DF1;
}

.font-subtitle.dark-mode {
    color: #5a189a;
}

.font-body.dark-mode {
    color: white;
}

.font-caption.dark-mode {
    color: #9D8DF1;
}

.form-control.dark-mode {
    background-color: #41463D;
    color: white;
    border: 1px solid #9D8DF1;
}

.form-control.dark-mode:focus {
    border-color: #5a189a;
    box-shadow: none;
}

.form-label.dark-mode {
    color: #9D8DF1;
}

.card.dark-mode {
    background-color: #333;
    border: none;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title.dark-mode {
    color: #9D8DF1;
}

.card-text.dark-mode {
    color: white;
}
/* 
.footer.dark-mode {
    background-color: #41463D;
    color: white;
    padding: 1rem;
    text-align: center;
} */

.d-flex.dark-mode .btn-primary {
    background-color: #9D8DF1;
    border-color: #9D8DF1;
    transition: background-color 0.3s ease;
}

.d-flex.dark-mode .btn-primary:hover {
    background-color: #5a189a;
    border-color: #5a189a;
}

.d-flex.dark-mode .btn-danger {
    background-color: #ff1744;
    border-color: #ff1744;
    transition: background-color 0.3s ease;
}

.d-flex.dark-mode .btn-danger:hover {
    background-color: #d50000;
    border-color: #d50000;
}


/* Dark mode styles */
body.dark-mode {
    background-color: #333;
    color: white;
}

.container.dark-mode {
    background-color: #41463D;
    color: white;
}

/* Ajustes específicos */
.profile-photo-edit.dark-mode {
    border: 2px solid white;
}

/* Estilos de navbar en modo oscuro */
.navbar.dark-mode {
    background-color: #41463D;
    color: white;
}

.navbar.dark-mode .brand-link {
    color: #9D8DF1;
}

.navbar.dark-mode .navbar-links a {
    color: #9D8DF1;
}

.navbar.dark-mode .navbar-links a:hover {
    color: #5a189a;
}

.btn-purple.dark-mode {
    background-color: #9D8DF1;
    color: white;
}

.btn-purple.dark-mode:hover {
    background-color: #5a189a;
    color: white;
}

.font-title.dark-mode {
    color: #9D8DF1;
}

.font-subtitle.dark-mode {
    color: #5a189a;
}

.font-body.dark-mode {
    color: white;
}

.font-caption.dark-mode {
    color: #9D8DF1;
}

/* .form-control.dark-mode {
    background-color: #41463D;
    color: white;
    border: 1px solid #9D8DF1;
} */

.form-control.dark-mode:focus {
    border-color: #5a189a;
    box-shadow: none;
}

.form-label.dark-mode {
    color: #9D8DF1;
}

.card.dark-mode {
    background-color: #333;
    border: none;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title.dark-mode {
    color: #9D8DF1;
}

.card-text.dark-mode {
    color: white;
}


/* Estilos de tarjeta */
.card {
    background-color: #333;
    border: none;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.75rem;
    color: #9D8DF1;
}

.card-text {
    font-family: 'Roboto', sans-serif;
    color: white;
}

/* Estilos para el modo oscuro */
a.form-control.dark-mode {
    color: #9D8DF1;
    font-size: 20px;
    align-items: center;
    /* background-color: #000; */

}
button.dark-mode {
    background-color:#ffd700;  /* Color amarillo */
    color: #6200ee;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;  /* Fuente en negrita */
    font-size: 1.1em; 
}
/* Efecto hover */
a.form-control:hover {
    border-bottom: 3px solid gold;
}

/* Efecto activo */
a.form-control.active {
    border-bottom: 3px solid gold;
}
h2.dark-mode{
    color: gold;  /* Color morado */
}
p.click.dark-mode{
    color:#9D8DF1;
}

/* div.dark-mode.model-header{
    color: #9D8DF1;
    background-color: #41463D;

} */

/* Estilos para el modo oscuro */


.modal-content.dark-mode {
    background-color: #41463D;
    color: white;
    border: none;
}

.dark-mode.modal-body {
    border-bottom: none;
    border-top: none;
    background-color: #333;
}

.form-control.dark-mode {
    background-color: #333;
    color: white;
    border: 1px solid #9D8DF1;
}

.form-label.dark-mode,
.card-title.dark-mode,
.card-text.dark-mode {
    color: #9D8DF1;
}

.btn-purple.dark-mode {
    background-color: #9D8DF1;
    color: white;
}

.btn-purple.dark-mode:hover {
    background-color: #5a189a;
    color: white;
}

.button.dark-mode {
    background-color: #333;
    color: white;
}
.dark-mode.modal-title.h4{
    color:#6200ee;
}

:root {
    --footer-bg: #333;
    --footer-text: white;
    --footer-brand-power: #9D8DF1;
    --footer-brand-labs: #5a189a;
    --footer-link: #9D8DF1;
    --footer-link-hover: #5a189a;
  }
  
  .footer.dark-mode {
    background-color: var(--footer-bg);
    color: var(--footer-text);
  }
  
  .footer.dark-mode .footer-brand .power {
    color: var(--footer-brand-power);
  }
  
 
  .footer.dark-mode a {
    color: var(--footer-link);
  }
  
  .footer.dark-mode a:hover {
    color: var(--footer-link-hover);
  }
  
  .footer.dark-mode p a {
    color: var(--footer-link);
  }
  
  .footer.dark-mode p a:hover {
    color: var(--footer-link-hover);
  }
  

  .features-section.dark-mode{
    background-color: #41463D;
  }

  p.dark-mode{
    color:white;
  }
  .contact-section.dark-mode{
    background-color: #41463D;
  }

  .font_color_style.dark-mode{
    color:#9D8DF1;
}
.faq-section.dark-mode{
    background-color: #41463D;
  }
  .faq-container.dark-mode{
    background-color: #41463D;
  }

  .title.dark-mode{
    color:#9D8DF1;
  }