/* ClinicalHistoryForm.css */
.clinical-history-form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.clinical-history-form h1, .clinical-history-form h3 {
  color: #007bff;
}

.clinical-history-form .form-group {
  margin-bottom: 15px;
}

.clinical-history-form .btn-primary {
  background-color: #6200EE;
  border-color: #6200EE;
  font-size:25px;
}

.clinical-history-form .btn-primary:hover {
  background-color: #9D8DF1;
  border-color: #9D8DF1;
}

.progress-bar {
  background: linear-gradient(to right, #9D8DF1, #6200EE);
}

.icon-box {
  margin-bottom: 20px;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin-bottom: 20px;
}

.card .form-group {
  margin-bottom: 15px;
}

.icon-box h4 {
  margin-bottom: 10px;
  color: #6200ee;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  font-weight: bold;  /* Fuente en negrita */
  font-size: 2em;  /* Ajuste del tamaño de la fuente */
}

.icon-box p {
  line-height: 1.6;
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  font-weight: bold;  /* Fuente en negrita */
}

.form-label-lg {
  font-size: 2.0rem;
}

.form-check-lg input {
  transform: scale(1.5);
}

.text-purple {
  color: #9d8df1;
  font-size: 1.25rem;
}

.text-purple:hover {
  color: #9d8df1;
}
/* ClinicalHistoryForm.css */
/* .clinical-history-form {
  padding: 20px;
}

.clinical-history-form .form-control-lg,
.clinical-history-form .form-select-lg {
  font-size: 18px;
}

.clinical-history-form .form-label-lg {
  font-size: 18px;
}

.clinical-history-form .form-check-lg {
  font-size: 18px;
}

.clinical-history-form .form-check-lg input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.clinical-history-form .form-check-lg label {
  margin-left: 5px;
} */


/* test */
/* ClinicalHistoryForm.css */
.clinical-history-form {
  padding: 20px;
  height: auto;
}

.clinical-history-form .form-control-lg,
.clinical-history-form .form-select-lg {
  font-size: 18px;
}

.clinical-history-form .form-label-lg {
  font-size: 18px;
}

.clinical-history-form .form-check-lg {
  font-size: 18px;
}

.clinical-history-form .form-check-lg input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.clinical-history-form .form-check-lg label {
  margin-left: 5px;
}

/* Custom styles for select dropdown */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-wrapper::after {
  content: '\25BC'; /* Down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-wrapper select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 1px solid #ccc;
  padding: 10px 40px 10px 10px;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.info-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ccc; /* Color gris */
  color: #fff; /* Color blanco para el texto */
  text-align: center;
  line-height: 24px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.info-circle:hover {
  background-color: #888; /* Color gris más oscuro para el hover */
}

.tooltip-inner {
  max-width: 300px; /* Ajusta el ancho máximo del Tooltip si es necesario */
  padding: 8px; /* Ajusta el padding del Tooltip */
  color: #fff; /* Color del texto del Tooltip */
  background-color: #333; /* Color de fondo del Tooltip */
  border-radius: 4px; /* Bordes redondeados del Tooltip */
}

@media (max-width: 768px) {
  .select-wrapper select {
      font-size: 16px; /* Smaller font size for mobile */
      padding: 10px 35px 10px 10px; /* Adjust padding for smaller screens */
  }

  .select-wrapper::after {
      top: 15px; /* Adjust arrow position */
      right: 5px; /* Adjust arrow position */
      font-size: 24px; /* Smaller arrow */
  }

  .form-control-lg, .form-select-lg {
      font-size: 16px; /* Smaller font size for mobile inputs */
  }

  .form-label-lg {
      font-size: 18px; /* Adjust label size for mobile */
  }
}
