.service-page {
  padding: 20px;
}

.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Añadir espacio entre las secciones y el carrusel */
}
