.pay-now-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.text-purple {
  color: #6200ee !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.list-group-item {
  border: none;
  padding: 10px 0;
}

.fa-check-circle {
  font-size: 1.2em;
}
