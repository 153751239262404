.doctors-list {
  margin-top: 20px;
}

.doctor-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
}

.doctor-card:hover {
  transform: scale(1.03);
}

.doctor-card .card-header {
  background-color: #fff;
  color: #6f42c1;
  font-weight: bold;
}

.doctor-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 15px;
}

.card-body {
  padding: 15px;
}

.card-text {
  margin-bottom: 10px;
  color: #333;
}
