@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 210 100% 97%;
    --foreground: 339 20% 20%;
    --primary: 308 56% 85%;
    --primary-foreground: 210 22% 22%;
    --secondary: 196 75% 88%;
    --secondary-foreground: 210 22% 22%;
    --accent: 211 86% 70%;
    --accent-foreground: 210 22% 22%;
    --destructive: 0 93% 73%;
    --destructive-foreground: 210 22% 22%;
    --muted: 210 100% 95%;
    --muted-foreground: 210 22% 22%;
    --card: 210 100% 97%;
    --card-foreground: 210 22% 22%;
    --popover: 0 0% 100%;
    --popover-foreground: 341 20% 22%;
    --border: 210 40% 80%;
    --input: 210 40% 56%;
    --ring: 210 40% 60%;
    --chart-1: 308 56% 85%;
    --chart-2: 211 96% 70%;
    --chart-3: 45 100% 80%;
    --chart-4: 180 60% 85%;
    --chart-5: 120 50% 80%;
    --radius: 1rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-body;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
}
