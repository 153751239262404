/* MainContent.css */
.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-5px);
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-content img {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 8px;
}

.title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.dark-mode {
  background-color: #333;
  color: #fff;
}

/* .light-mode {
  background-color: #f8f9fa;
  color: #333;
} */

.main-content {
  width: 100%;
  max-width: auto;
  margin: 0 auto;
}

.hero, .contact {
  text-align: center;
  padding: 50px;
}

.contact {
  background-color: #eee;
  padding: 50px;
  margin-top: 20px;
}

.icon-box {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.1);
  position: relative;
}

.icon-box .icon {
  font-size: 48px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.icon-box .icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: #00b388;
  border-radius: 50%;
  z-index: -1;
}

.icon-box h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.icon-box h4 a {
  color: #6200ee;
  text-decoration: none;
}

.icon-box p {
  font-size: 1.1em;
}
/* MainContent.css */

.why-us {
  background: #6200ee;
  padding: 60px 0;
}

  .why-us .content {
    background: #6200ee; 
    color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

/* .why-us .content h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
*/
.why-us .content p {
  
  line-height: 1.6;
  
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;  /* Fuente en negrita */
  
} 

/* .why-us .button_style {
  background: #28a745;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  transition: background 0.3s;
}

.why-us .button_style:hover {
  background: #218838;
} */

.icon-box {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 10px 0;
}

.icon-box i {
  font-size: 40px;
  color: #6200ee;
  margin-bottom: 10px;
}

.icon-box h4 {
  margin-bottom: 10px;
  color: #6200ee;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  font-weight: bold;  /* Fuente en negrita */
  font-size: 2em;  /* Ajuste del tamaño de la fuente */
}

.icon-box p {
  
  line-height: 1.6;
  
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;  /* Fuente en negrita */
  
  
}

/* .dark-mode .why-us {
  background: #1c1c1c;
} */

.dark-mode .content {
  background: #0056b3;
}

.dark-mode .icon-box {
  background: #343a40;
  color: #fff;
}

.dark-mode .icon-box i {
  color: #28a745;
}
.section-title {
  margin-bottom: 30px;
}
.title-cat {
  margin-bottom: 10px;
  color: #6200ee;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  font-weight: bold;  /* Fuente en negrita */
  font-size: 3em;  /* Ajuste del tamaño de la fuente */
}

.p-landing{
    line-height: 1.6;
      font-size: 16px;
      margin-bottom: 10px;
      color: #333;
      text-decoration: none;
      padding: 10px;
      display: inline-block;
      transition: color 0.3s ease, border-bottom 0.3s ease;
      font-weight: bold;  /* Fuente en negrita */
}

.slider-container{
  height:auto;
}
