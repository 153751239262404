.contact-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .ticket-form {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 30px;
  }
  
  .ticket-form .form-group {
    margin-bottom: 20px;
  }
  
  .ticket-form .form-control {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .ticket-form .form-control:focus {
    border-color: #007bff;
    box-shadow: none;
  }
  
  .btn-custom {
    background-color: #00b388;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .btn-custom:hover {
    background-color: #008f72;
  }
  
  .faq-section {
    text-align: left;
  }
  
  .accordion-button {
    background: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .accordion-body {
    background: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .dark-mode .contact-section {
    background: #333;
    color: white;
  }
  
  .light-mode .contact-section {
    background: #f8f9fa;
    color: black;
  }
  
  .dark-mode .ticket-form {
    background: #41463D;
    color: white;
  }
  
  .light-mode .ticket-form {
    background: #fff;
    color: black;
  }
  
  .dark-mode .form-control {
    background: #555;
    color: white;
  }
  
  .light-mode .form-control {
    background: #fff;
    color: black;
  }
  
  .dark-mode .accordion-button {
    background: #555;
    color: white;
  }
  
  .light-mode .accordion-button {
    background: #fff;
    color: black;
  }
  
  .dark-mode .accordion-body {
    background: #666;
    color: white;
  }
  
  .light-mode .accordion-body {
    background: #f9f9f9;
    color: black;
  }
