.testimonial-item {
    display: ruby;
    align-items: center;
    padding: 10px;
    background-color: rgba(255, 193, 7, 0.7);/* verde esmeralda translúcido al 30% */
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .testimonial-item:hover {
    background-color: rgba(255, 193, 7, 0.99) /* color de transición más oscuro y translúcido al 50% */
  }
  
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-content h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .testimonial-content p {
    margin: 5px 0 0;
    font-size: 1rem;
    color: #333;
  }
  