.container-color{
    background-color: #41463D;
    color: white;

}


.btn-purple {
    background-color: #9D8DF1;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

.btn-purple:hover {
    background-color: #5a189a;
    color: white;
}
