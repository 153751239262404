/* src/components/Alert.css */
.alert {
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    position: relative;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    cursor: pointer;
}
