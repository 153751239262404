/* Light Mode Styles */
body.light-mode {
    background-color: white;
    color: black;
}


/* Ajustes específicos */
.profile-photo-edit.light-mode {
    border: 2px solid black;
}

/* Estilos de navbar en modo claro */
.navbar.light-mode {
    background-color: #f8f9fa;
    color: black;
}

.navbar.light-mode .brand-link {
    color: black;
}

div.dropdown-menu{
    background-color: #fff;
}

button.light-mode {
    background-color:#6200ee;   /* Color amarillo */
    color: #ffd700;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    font-weight: bold;  /* Fuente en negrita */
    font-size: 1.1em; 
}
/* Estilos para el modo claro */
a.form-control.light-mode {
    color: #6200ee;  /* Color morado */
    background-color: #fff;
}


h2.light-mode{
    color: #6200ee;  /* Color morado */
}

.navbar.light-mode .navbar-links a {
    color: #6200ee;
}

.navbar.light-mode .navbar-links a:hover {
    color: #3700b3;
}

.btn-purple.light-mode {
    background-color: #6200ee;
    color: white;
}

.btn-purple.light-mode:hover {
    background-color: #3700b3;
    color: white;
}

.font-title.light-mode {
    color: #6200ee;
}

.font-subtitle.light-mode {
    color: #3700b3;
}

.font-body.light-mode {
    color: black;
}

.font-caption.light-mode {
    color: #6200ee;
}

.form-control.light-mode {
    background-color: white;
    color: black;
    border: 1px solid #6200ee;
}

.form-control.light-mode:focus {
    border-color: #3700b3;
    box-shadow: none;
}

.form-label.light-mode {
    color: #6200ee;
}

.card-title.light-mode {
    color: #6200ee;
}

.card-text.light-mode {
    color: black;
}

.footer.light-mode {
    background-color: white;
    color: black;
    padding: 1rem;
    text-align: center;
}

.d-flex.light-mode .btn-primary {
    background-color: #6200ee;
    border-color: #6200ee;
    transition: background-color 0.3s ease;
}

.d-flex.light-mode .btn-primary:hover {
    background-color: #3700b3;
    border-color: #3700b3;
}

.d-flex.light-mode .btn-danger {
    background-color: #ff1744;
    border-color: #ff1744;
    transition: background-color 0.3s ease;
}

.d-flex.light-mode .btn-danger:hover {
    background-color: #d50000;
    border-color: #d50000;
}

/* Light Mode Styles */
body.light-mode {
    background-color: white;
    color: black;
}

.container.light-mode {
    background-color: white;
    color: black;
}

/* Ajustes específicos */
.profile-photo-edit.light-mode {
    border: 2px solid black;
}

/* Estilos de navbar en modo claro */
.navbar.light-mode {
    background-color: #f8f9fa;
    color: black;
}

.navbar.light-mode .brand-link {
    color: black;
}

.navbar.light-mode .navbar-links a {
    color: #6200ee;
}

.navbar.light-mode .navbar-links a:hover {
    color: #3700b3;
}

.btn-purple.light-mode {
    background-color: #6200ee;
    color: white;
}

.btn-purple.light-mode:hover {
    background-color: #3700b3;
    color: white;
}

.font-title.light-mode {
    color: #6200ee;
}

.font-subtitle.light-mode {
    color: #3700b3;
}

.font-body.light-mode {
    color: black;
}

.font-caption.light-mode {
    color: #6200ee;
}

.form-control.light-mode {
    background-color: white;
    color: black;
    border: 1px solid #6200ee;
}

.form-control.light-mode:focus {
    border-color: #3700b3;
    box-shadow: none;
}

.form-label.light-mode {
    color: #6200ee;
}

.card.light-mode {
    background-color: white;
    border: none;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title.light-mode {
    color: #6200ee;
}

.card-text.light-mode {
    color: black;
}

.footer.light-mode {
    background-color: white;
    color: black;
    padding: 1rem;
    text-align: center;
}
.light-mode.modal-title.h4{
    color:#6200ee;
}
.font_color_style.light-mode{
    color:#6200ee;

}
h5.light-mode{
    color:#6200ee;
}


.title.dark-mode{
    color:#6200ee;
  }
/* Light Mode Styles */
.light-mode .hero,
.light-mode .contact,
.light-mode .card-content,
.light-mode .card-image img {
  background-color: #f9f9f9;
  color: #333;
}

.light-mode .card {
  border: 1px solid #ddd;
}

.light-mode .icon-box {
  background: #fff;
  border: 1px solid #ddd;
}

.light-mode .icon-box .icon::before {
  background-color: #00b388;
}


