.billing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .billing-card {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border-color: #6200ee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .billing-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .billing-item {
    text-align: center;
  }
  
  .billing-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .amount {
    font-size: 24px;
    font-weight: bold;
  }
  