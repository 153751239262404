.register-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
    color:black;
}

.register-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.register-button {
    background-color: #6200ee;
    color: white;
    padding: 12px;
   /* Margen entre botones */
    border: none;
    border-radius: 20px; /* Forma de cápsula */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.register-button:hover {
    background-color: #4B0082;
    color:white;
     /* Color morado fuerte al hacer hover */
}
/* nav-item
/* Additional styles for better accessibility */
input:focus {
    border-color: #007bff;
    outline: none;
}
/* register.css */
.password-input-container {
    display: flex;
    align-items: center;
}

.password-input-container input {
    flex: 1;
}

.password-input-container button {
    margin-left: 10px;
    padding: 6px 12px;
    cursor: pointer;
}

/* register.css */
/* .register-container {
    max-width: 400px;
    margin: auto;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
} */

.form-group {
    margin-bottom: 1rem;
}

.password-input-container {
    display: flex;
    align-items: center;
}

.password-input-container input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.show-hide-button {
    margin-left: 10px;
    padding: 0.5rem;
    border: none;
    background-color:goldenrod;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.show-hide-button:hover {
    background-color: gold;
}


.register-button:disabled {
    background-color: #94d3a2;
    cursor: not-allowed;
}

.register-button:hover:not(:disabled) {
    background-color: #6c00fa;
}


.highlight {
    background-color: gold;
    font-weight: bold;
    color:#010101;
}

@media (max-width: 768px) {
    .register-button {
        width: 100%;
        padding: 20px 80px; /* Más espacio para un botón más grande */
        margin: 10px;
    }

    .register-form {
        padding: 1rem; /* Ajustar el relleno del formulario en móviles */
    }
}

.form-group.terms {
    display: flex;
    align-items: center;
}

.form-group.terms input[type="checkbox"] {
    margin-right: 10px;
}

.terms-label {
    display: flex;
    align-items: center;
    white-space: nowrap; /* Esto asegurará que el texto no se divida en varias líneas */
}