
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #fff; /* Puedes ajustar este valor con darkMode usando una clase */
    color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
  }
  
  .navbar-container.dark-mode {
    background-color: #41463D;
    color: #fff;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .navbar-links li {
    margin-right: 20px;
  }
  
  .navbar-links.open {
    flex-direction: column;
    width: 100%;
  }
  
  .profile-menu-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    z-index: 1000;
  }
  
  .dropdown-menu.dark-mode {
    background-color: #41463D;
    color: #fff;
  }
  
  .profile-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .burger {
    display: none;
    cursor: pointer;
  }
  
  .burger div {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px;
    transition: all 0.3s ease;
  }
  
  .burger.dark-mode div {
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .navbar-item {
      width: 100%;
      text-align: left;
    }
  
    .dropdown-container {
      position: static;
      box-shadow: none;
      display: block;
      padding-left: 20px;
    }
    .navbar-container {
      flex-wrap: wrap;
    }
  
    .navbar-links {
      flex-direction: column;
      width: 100%;
      display: none;
    }
    .burger {
      display: block;
    }
    .navbar-links {
      display: none;
    }
    .navbar-links.open {
      display: flex;
    }
    .navbar-links li {
      margin: 10px 0;
    }
    .mobile-menu {
      display: none;
    }
  
    .mobile-menu.open {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: var(--dropdown-background-color);
      color: var(--dropdown-color);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 999;
    }
    .login-button{
      color: black;
    }
  }
  