.canvas-container {
    color: #000;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background-color: #fff;
    overflow: hidden; /* Ensure no overflow on small screens */
}
  
  .canvas-container::-webkit-scrollbar {
    width: 0;
  }
  
  .canvas-container * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
  }
  
  .canvas-container .banner {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    position: relative;
    background-color: white;
  }
  
  .canvas-container h1, .canvas-container h2, .canvas-container h3, .canvas-container h4, .canvas-container h5, .canvas-container h6 {
    font-weight: 500;
    position: relative; /* Ensure text is above the canvas */
    z-index: 1; /* Ensure text is above the canvas */
  }
  
  .canvas-container .banner button {
    all: unset;
    border: 1px solid #afaeae55;
    padding: 10px 20px;
    border-radius: 20px;
    background-image: linear-gradient(
      to bottom, #fff1, transparent, #fff1
    );
    cursor: pointer;
    background-color: #6200ee;
    color:#fff;
    transition: 0.5s;
    position: relative; /* Ensure button is above the canvas */
    z-index: 1; /* Ensure button is above the canvas */
  }
  
  .canvas-container .banner button:hover {
    background-color: #c691e6;
    color: #040018;
    box-shadow: 0 0 50px #c691e6;
  }
  
  .canvas-container h1 {
    --to: left;
    font-size: 4em;
    font-weight: bold;
    background-image: linear-gradient(
      to var(--to), #6200ee, #9d8df1, #007BFF
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    line-height: 1em;
  }
  
  .canvas-container h1.right {
    --to: right;
  }
  
  .canvas-container .banner {
    position: relative;
  }
  
  .canvas-container .banner #dotsCanvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0; /* Ensure canvas is below the text and button */
  }
  

@media (max-width: 768px) {
    .canvas-container .banner {
        height: 60vh; /* Adjusted height for mobile view */
        padding: 20px;
    }

    .canvas-container h1 {
        font-size: 2em;
    }

    .canvas-container .banner button {
        padding: 8px 16px;
    }

    .canvas-container h4 {
        font-size: 0.9em;
    }
}
