.banner-header {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 40px;
  }
  
  .banner-image-wrapper {
    max-height: 400px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .banner-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  