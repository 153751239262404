.data-analysis-container {
    margin: 20px;
}

.data-analysis-form .form-group {
    margin-bottom: 15px;
}

.data-analysis-button {
    padding: 10px 20px;
}

.result {
    margin-top: 20px;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
}

.close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}
