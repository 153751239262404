.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;/*Ocupa toda la altura del viewport */
  margin: 0;
}

.banner {
  background-color: rgba(98, 0, 238, 0.3); /* Morado con 70% de transparencia */
  border-radius: 15px; /* Bordes redondeados */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1600px; /* Ancho máximo */
  height: auto; /* Altura fija */
}

.banner-svg {
  width: 25%;
  height: 100%;
}

.banner-content {
  color: white;
  padding-left: 20px;
  flex: 1;
  
}

.banner-content h2 {
  color: #6200ee; /* Título en morado */
  font-size: 2em;
  margin: 0;
}

.banner-content p {
  color: black;
  font-size: 1.2em;
  margin: 10px 20px;
  padding: 0px 0px 80px 0px;
}

.banner-button {
  margin: 20px 40px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #6200ee;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.banner-button:hover {
  background-color: #9D8DF1;
}
@media (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .banner-content {
    margin-top: 20px;
  }

  .banner-button {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;
  }

  .banner-svg {
    width: 100%;
    height: auto;
    max-width: 300px; /* Ensure it doesn't get larger than the original size */
  }
}