.profile-photo-edit {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0 auto; /* Centra la imagen horizontalmente */
}
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1600px !important;
    }
}
/* General Container */
.container-color {
    background-color: #41463D;
    color: white;
}

/* Botones */
.btn-purple {
    background-color: #9D8DF1;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

.btn-purple:hover {
    background-color: #5a189a;
    color: white;
}

/* Estilos de fuente */
.font-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    color: #9D8DF1;
}

.font-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #5a189a;
}

.font-body {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: white;
}

.font-caption {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: #9D8DF1;
}

/* Estilos de enlace */
/* a {
    color: #9D8DF1;
    text-decoration: none;
    transition: color 0.3s ease;
} */

a:hover {
    color: #5a189a;
}

/* Estilos de formulario */
/* .form-control {
    background-color: #41463D;
    color: white;
    border: 1px solid #9D8DF1;
} */

.form-control:focus {
    border-color: #5a189a;
    box-shadow: none;
}

.form-label {
    color: #9D8DF1;
}

/* Estilos de tarjeta */
.card {
    background-color: #6200ee;
    border: none;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.75rem;
    color: #9D8DF1;
}

.card-text {
    font-family: 'Roboto', sans-serif;
    color: b;
}

/* Estilos de navbar */
.navbar {
    background-color: #41463D;
    color: white;
}

.navbar .brand-link {
    color: #9D8DF1;
}

.navbar .navbar-links a {
    color: #9D8DF1;
    transition: color 0.3s ease;
}

.navbar .navbar-links a:hover {
    color: #5a189a;
}

