
.products-dropdown {
    width: 40rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .columns-container {
    display: flex;
    justify-content: space-between;
  }
  
  .services-list {
    padding: 0;
    margin: 0;
    list-style: none;
    flex: 1;
  }
  
  .services-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .services-list a {
    text-decoration: none;
    color: #6f42c1;
    font-weight: bold;
    margin-left: 0.5rem;
    transition: color 0.3s;
  }
  
  .services-list a:hover {
    color: #4b2a8f;
    text-decoration: underline;
  }
  
  .circle-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #6f42c1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .circle-icon svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    .products-dropdown {
      width: 100%;
    }
    
    .columns-container {
      flex-direction: column;
    }
    
    .services-list {
      width: 100%;
    }
  }