.banner-carousel {
    width: 100%;
    background-color: rgba(255, 193, 7, 0.3); /* verde esmeralda translúcido al 30% */
    border-radius: 15px;
    padding: 10px;
    overflow: hidden;
  }
  
  .slick-slide {
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0.3; /* Los elementos inactivos serán translúcidos */
    margin: 0 10px; /* Añadir margen entre los testimonios */
  }
  
  .slick-center {
    transform: scale(1.1); /* Escala el elemento activo */
    opacity: 1; /* El elemento activo será opaco */
  }
  
  .slick-list {
    padding: 0 30px; /* Reducir espaciado para centrar los elementos */
  }
  
  .slick-dots {
    display: none; /* Ocultar los puntos de navegación */
  }
  
  @media (max-width: 768px) {
    .slick-slide {
      opacity: 1;
      transform: scale(1); /* No aplicar la escala en móviles */
      margin: 0; /* Sin margen en móviles */
    }
  }
  