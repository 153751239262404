/* Payment.css */
.payment-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .card-element-wrapper {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .card-element {
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
  }
  
  .StripeElement {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .text-purple{
    color:#6200EE;
  }