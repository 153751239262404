.features-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .icon-box {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    transition: all 0.3s ease;
    border-radius: 8px;
    text-align: center;
    flex: 1 1 calc(25% - 30px); /* Four boxes per row with some margin */
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .icon-box .icon {
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  .icon-box h4 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .icon-box p {
    font-size: 15px;
    /* color: #777; */
    margin-bottom: auto; /* Pushes content to the top */
  }
  
  .icon-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .icon-box a {
    color: inherit;
    text-decoration: none;
  }
  
  .icon-box a:hover {
    color: #00b388;
  }
  
  .dark-mode .features-section {
    background: #333;
    color: white;
  }
  
  .light-mode .features-section {
    background: #f8f9fa;
    color: black;
  }
  
  .dark-mode .icon-box {
    background: #41463D;
    color: white;
  }
  
  .light-mode .icon-box {
    background: #fff;
    color: black;
  }
  
  .dark-mode .icon-box a {
    color: #9D8DF1;
  }
  
  .light-mode .icon-box a {
    color: #6200ee;
  }
  
  .dark-mode .icon-box a:hover {
    color: #5a189a;
  }
  
  .light-mode .icon-box a:hover {
    color: #3700b3;
  }
  
  .dark-mode .section-title {
    color: white;
  }
  
  .light-mode .section-title {
    color: black;
  }

  .divider{
  
    height: 12px;
    width: 100px;
    border-top: 8px solid #5a189a;
    border-radius: 5px;
    justify-self: center;
    background-color: #5a189a;
    margin: 10px auto 0; /* Centrando la línea */



  }
  
  