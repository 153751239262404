.resources-dropdown {
    display: flex;
    flex-direction: column;
      width: 18.5rem;
      padding: 1rem;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .flex {
    display: flex;
    justify-content: space-between;
  }
  
  .flex > div {
    flex: 1;
    margin-right: 20px;
  }
  
  .flex > div:last-child {
    margin-right: 0;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .heading-link {
    margin-top: 20px;
  }
  
  .heading-link a {
    text-decoration: none;
    color: inherit;
  }
  
  .heading-link a:hover {
    text-decoration: underline;
  }
  