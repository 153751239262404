.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--footer-bg);
  color: var(--footer-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-column {
  flex: 1;
  padding: 10px;
}

.footer-logo {
  font-size: 24px;
}

.footer-brand {
  display: flex;
  align-items: center;
}

.footer-brand .power {
  font-weight: bold;
  font-size: 24px;
  color: var(--footer-brand-power);
}

.footer-brand .labs {
  font-weight: bold;
  font-size: 24px;
  color: gold;
}

.footer-description {
  margin-top: 10px;
}

.footer-column a {
  display: block;
  margin: 5px 0;
  color: var(--footer-link);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column a:hover {
  color: #6200ee;
}

.footer p {
  margin: 0;
}

.footer p a {
  color: var(--footer-link);
}

.footer p a:hover {
  color: #6200ee;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f8f9fa;
}

.footer.dark-mode {
  background-color: #333;
  color: #fff;
}

.footer.light-mode {
  background-color: #f8f9fa;
  color: #000;
}

.footer-column {
  flex: 1;
  margin: 10px;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-divider {
  border: 0;
  height: 3px;
  background: #9D8DF1;
  margin-bottom: 10px;
}

.footer a {
  display: block;
  margin-bottom: 5px;
  color: #9D8DF1;
  text-decoration: none;

}

.footer a:hover {
  text-decoration: underline;
}

.footer-description {
  margin-top: 10px;
}
/* footer.css */

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.footer-column {
  flex: 1;
  min-width: 250px; /* Ajusta este valor según sea necesario */
  margin: 10px;
}

.footer-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.footer-divider {
  border: 2px solid #6200ee; /* Color de la línea */
  width: 50%;
  margin: 0 auto 20px;
}

.footer-description {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer-column {
    width: 100%;
    text-align: center;
  }
  .footer-divider {
    width: 50%;
  }
}

/* LightMode.css */

.light-mode .footer {
  background-color: #f8f9fa;
  color: #333;
}

/* DarkMode.css */

.dark-mode .footer {
  background-color: #333;
  color: #fff;
}
