/* src/components/Terms.css */
.terms-card {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-container h1,
.terms-container h2 {
    color: #333;
    margin-bottom: 15px;
}

.terms-container p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

.terms-container h2 {
    margin-top: 20px;
}
