/* styles.css */
.activate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 100px); /* Ajustar según el tamaño del navbar y footer */
    padding: 50px 20px; /* Padding adicional entre el navbar y footer */
    background-color: #f9f9f9;
}

.activate-card {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    width: 100%;
    margin: 20px; /* Para que no se vea tan pegado al navbar y footer */
}

.activate-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.activate-container p {
    font-size: 1.25rem;
    color: #666;
}

.activate-container .message {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #4CAF50; /* Green color for success */
}

.activate-container .error {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #f44336; /* Red color for error */
}
