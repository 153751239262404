/* .resources-page {
    padding: 20px;
  }
  
  .resources-content {
    margin-top: 20px;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .banner-header {
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 50px 0;
  }
  
  .banner {
    position: relative;
  }
  
  .banner-svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  
  .banner-content {
    position: relative;
    padding: 20px;
  }
  
  .banner-content h2 {
    margin: 0;
    font-size: 2rem;
  }
  
  .banner-content p {
    margin-top: 10px;
    font-size: 1rem;
  }
  
  .banner-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6200ee;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .banner-button:hover {
    background-color: #4500b5;
  }
   */

   .resources-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial, sans-serif';
  }
  
  .resources-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  