.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.section:hover {
  transform: translateY(-5px);
}

.section-image {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.section-content {
  text-align: center;
}

.section-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.section-text {
  font-size: 16px;
  color: #666;
  text-align: left;
  max-width: 800px;
  line-height: 1.6;
}

  