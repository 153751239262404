
.faq-container {
    padding: 50px 0;
    text-align: center;
  }
  
  .faq-section {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 8px;
    text-align: left;
    margin: 0 auto;
    max-width: 800px;
  }
  
  .faq-section .accordion-header .accordion-button {
    background-color: #f8f9fa;
    color: #333;
    font-weight: bold;
    border: none;
  }
  
  .faq-section .accordion-header .accordion-button.collapsed {
    background-color: #e9ecef;
  }
  
  .faq-section .accordion-header .accordion-button::after {
    color: #333;
  }
  
  .faq-section .accordion-body {
    background-color: #f8f9fa;
    color: #333;
  }
  
  .dark-mode .faq-section {
    background: #41463D;
    color: white;
  }
  
  .light-mode .faq-section {
    background: #fff;
    color: black;
  }
  
  .dark-mode .accordion-header .accordion-button {
    background-color: #555;
    color: white;
  }
  
  .dark-mode .accordion-header .accordion-button.collapsed {
    background-color: #666;
  }
  
  .dark-mode .accordion-header .accordion-button::after {
    color: white;
  }
  
  .dark-mode .accordion-body {
    background-color: #555;
    color: white;
  }
  