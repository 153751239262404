.profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%; /* Hace que la imagen sea redonda */
    object-fit: cover;  /* Asegura que la imagen se escale y recorte para llenar el contenedor */
  }

  .font-purple {
    color: #6200ee;
    font-size: 20px;
  }
  .font-white {
    color: #f1f1f1;
    font-size: 20px;
  }
  
  

  @media (min-width: 768px) {
    .card-container {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
    }
  }
  
  .card-full-width {
    width: 100%;
  }
  
  .card-two-thirds {
    width: 66.67%;
  }
  
  .card-one-third {
    width: 33.33%;
  }